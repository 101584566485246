<script>
    import {onMount} from 'svelte';

    export let title, message
    export let cancel_button_label = gon.modal_cancel
    export let action_button_label = gon.modal_execute

    // Usage-example: /frontend/javascript/views/project/form-controller.js:60

    let overlay_tag, content_tag

    onMount(() => {
        content_tag.addEventListener('click', (ev) => handleClickContent(ev))
        overlay_tag.addEventListener('click', (ev) => handleClose(ev))
        if (action_button_label === 'unset') {
            action_button_label = gon.modal_execute
        }
    })

    function handleClose() {
        overlay_tag.parentElement.remove()
    }

    function handleClickContent(e) {
        e.stopPropagation()
    }

    function handleAction() {
        const ev = new Event('action')
        overlay_tag.parentElement.dispatchEvent(ev)
        handleClose()
    }

</script>

<div class="modal-overlay" bind:this={overlay_tag}>
    <div class="modal-content" bind:this={content_tag}>
        <h1>{title}</h1>

        {#each message.split('<br>') as msg}
            <p>{msg}</p>
        {/each}

        <div class="buttons">
            <button class={`button2 secondary cancel ${action_button_label !== 'null' ? 'margin-right' : ''}`}
                    on:click={() => handleClose()}>{cancel_button_label}</button>
            {#if action_button_label !== 'null'}
                <button class="button2 primary execute" on:click={() => handleAction()}>{action_button_label}</button>
            {/if}
        </div>
    </div>
</div>
window.add_flash_alert = function(message) {
    const box = document.getElementById('flashes-box')
    const alert = document.createElement('div')
    alert.innerHTML = message
    alert.classList.add('alert')
    box.appendChild(alert)
}

window.add_flash_notice = function(message) {
    const box = document.getElementById('flashes-box')
    const alert = document.createElement('div')
    alert.innerHTML = message
    alert.classList.add('notice')
    box.appendChild(alert)
}
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        const links = this.element.querySelectorAll('[data-id]')
        for (const link of links) {
            const id = link.getAttribute('data-id')
            link.addEventListener('click', () => this.handle_click(id))
        }
    }

    handle_click(id) {

        const wrapper = document.querySelector('.scrollable-content')
        const el = wrapper.querySelector(`#${id}`)


        el.scrollIntoView()

    }

}